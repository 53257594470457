import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from "gatsby";
import { BiMailSend } from "react-icons/bi";
import { Page } from "@/components/Page.tsx";
import avatarDarkmode from "@/images/avatar-darkmode.svg";
import avatarDefault from "@/images/avatar-default.svg";
import github from "@/images/github.svg";
import linkedin from "@/images/linkedin.svg";
import "@/styles/Landing.scss";
export const _frontmatter = {
  "title": "Vivek Rajagopal",
  "path": "/"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Page className="landing" mdxType="Page">
      <div className="heading">
  <h1>
    <span>V</span>
    <span>i</span>
    <span>v</span>
    <span>e</span>
    <span>k</span>
    <span>&nbsp;</span>
    <span>R</span>
    <span>a</span>
    <span>j</span>
    <span>a</span>
    <span>g</span>
    <span>o</span>
    <span>p</span>
    <span>a</span>
    <span>l</span>
  </h1>
  <img src={avatarDefault} className="avatar default" alt="Logo" />
  <img src={avatarDarkmode} className="avatar darkmode" alt="Logo" />
      </div>
      <p>{`Hey, I'm Vivek 👋`}</p>
      <p>{`I like building interesting products and tools with code and enjoy working on all aspects from
front-end, to back-end infrastructure and dev-ops. My favourite languages are TypeScript and
`}<a parentName="p" {...{
          "href": "https://fsharpforfunandprofit.com/why-use-fsharp/"
        }}>{`F#`}</a>{`.`}</p>
      <p>{`I'm a huge fan of simulation games like `}<a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=5yLaYuuBWRM"
        }}>{`Factorio`}</a>{` and
SimCity, and immersive-sim style games like Dishonored and Deus Ex.`}</p>
      <p>{`Check out my `}<Link to="/blog" mdxType="Link">{`blog`}</Link>{`.`}</p>
      <p>{`If you want to chat, say
`}<a parentName="p" {...{
          "href": "mailto:hello@vivekrajagopal.dev"
        }}>{`hello at vivekrajagopal.dev`}</a>{` `}<BiMailSend style={{
          "verticalAlign": "middle"
        }} mdxType="BiMailSend" /></p>
      <div className="socials">
  <a href="https://github.com/VivekRajagopal"><img className="link" src={github} alt="GitHub Vivek Rajagopal" /></a>
  <a href="https://www.linkedin.com/in/vivek-rajagopal-0a60ab9b/"><img className="link" src={linkedin} alt="LinkedIn Vivek Rajagopal" /></a>
      </div>
    </Page>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      